/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader(callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function() {
  }
  window.addEventListener(
    'scroll',
      // eslint-disable-next-line no-unused-vars
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE() {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}

export function scorePassword(pass) {
  let score = 0
  if (!pass) {
    return score
  }
  // award every unique letter until 5 repetitions
  const letters = {}
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1
    score += 5.0 / letters[pass[i]]
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass)
  }

  let variationCount = 0
  for (var check in variations) {
    variationCount += (variations[check] === true) ? 1 : 0
  }
  score += (variationCount - 1) * 10

  return parseInt(score)
}

export function notifyMe() {
  if (!('Notification' in window)) {
    alert('当前浏览器不支持桌面通知')
    return
  }
  if (Notification.permission === 'granted') {
    const notification = new Notification('桌面通知', {
      dir: 'auto',
      lang: 'zh',
      tag: 'testTag',
      icon: 'http://img.hyebin.top/2020/10/23/e17d1ccf96c97.jpg',
      body: '今天真是个好天气',
      renotify: true
    })
    notification.onclick = () => {
      window.focus()
    }
    return
  }
  if (Notification.permission !== 'denied') {
    Notification.requestPermission(permission => {
      if (permission === 'granted') {
        const notification = new Notification('桌面通知', {
          dir: 'auto',
          lang: 'zh',
          tag: 'testTag',
          icon: 'http://img.hyebin.top/2020/10/23/e17d1ccf96c97.jpg',
          body: '今天真是个好天气',
          renotify: true
        })
        notification.onclick = () => {
          window.focus()
        }
      }
    })
  }
}

// 递归处理树形数据叶节点
export function recursionHandleTree(arr, childrenKey, leafKey) {
  arr.forEach(el => {
    if (el[childrenKey]?.length) {
      recursionHandleTree(el[childrenKey], childrenKey, leafKey)
    } else {
      el[leafKey] = true
    }
  })
}
