<template>
  <div>
    <a-modal
      title="创建成员"
      :visible="addVisible"
      :mask-closable="false"
      :cancel-text="null"
      width="35%"
      wrap-class-name="add-member"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="cancelEdit('ruleForm')">
          取消
        </a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="saveEdit('ruleForm')">
          确定
        </a-button>
      </template>
      <div class="modal-content edit">
        <div class="img">
          <!--<img :src="require('@/' + formData.url)" width="60" height="60" class="img" alt="">-->
        </div>
        <a-form-model
          ref="ruleForm"
          :model="formData"
          :rules="rules"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          label-align="right"
          layout="inline"
        >
          <a-row>
            <a-col :span="24">
              <a-form-model-item ref="userName" label="头像" prop="fileUrl">
                <a-upload
                    name="file"
                    accept="image"
                    :show-upload-list="false"
                    :before-upload="beforeUpload"
                    :custom-request="customRequest"
                    class="mr-10"
                >
                  <a-avatar v-if="isUpload" :loading="imgLoading" icon="user" :size="50" shape="square" :src="fileData.fileUrl" />
                  <a-avatar v-else :size="50" icon="user" :loading="imgLoading" shape="square" :src="formData.portraitFileUrl ? formData.portraitFileUrl : undefined" />
                </a-upload>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item ref="nickName" label="昵称" prop="nickName">
                <a-input v-model="formData.nickName" @blur="() => { $refs.nickName.onFieldBlur() }" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item ref="userCode" label="工号" prop="userCode">
                <a-input v-model="formData.userCode" @blur="() => { $refs.userCode.onFieldBlur() }" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item ref="tel" label="手机" prop="tel" required>
                <a-input v-model="formData.tel" @blur="() => { $refs.tel.onFieldBlur() }" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item ref="landline" label="座机" prop="landline">
                <a-input v-model="formData.landline" @blur="() => { $refs.landline.onFieldBlur() }" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="性别" prop="sex">
                <a-radio-group v-model="formData.sex">
                  <a-radio :value="0">男</a-radio>
                  <a-radio :value="1">女</a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item ref="password" label="密码" help="若用户已存在，则此密码无效" prop="password">
                <a-input v-model="formData.password" @blur="() => { $refs.password.onFieldBlur() }" />
              </a-form-model-item>
            </a-col>
            <!--<a-col :span="12">-->
            <!--  <a-form-model-item label="状态" prop="isEnable">-->
            <!--    <a-radio-group v-model="formData.isEnable">-->
            <!--      <a-radio :value="1">启用</a-radio>-->
            <!--      <a-radio :value="0">禁用</a-radio>-->
            <!--    </a-radio-group>-->
            <!--  </a-form-model-item>-->
            <!--</a-col>-->
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="地址" prop="address">
                <a-input v-model="formData.address" type="textarea" :rows="2" style="resize:none" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="备注" prop="remark">
                <a-input v-model="formData.remark" type="textarea" :rows="3" style="resize:none" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-divider />

          <a-row>
            <a-col :span="24">
              <a-form-model-item label="角色" prop="userRole">
                <a-select
                  v-model="formData.userRole"
                  mode="tags"
                  placeholder="角色"
                  :label-in-value="true"
                >
                  <a-select-option v-for="item in roleList" :key="item.roleId.toString()">
                    {{ item.roleName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <template v-if="formData.userDepartmentList.length === 0">
              <a-form-model-item label="部门" prop="userDepartmentList">
                - -
              </a-form-model-item>
            </template>
            <template v-else>
              <a-form-model-item
                v-for="(item, index) in formData.userDepartmentList"
                :key="index"
                :label="index === 0 ?'部门': ' '"
                :colon="index === 0"
                class="department-list"
                :required="index === 0"
              >
                <div class="department-list-item">
                  <a-col :span="15">
                    <a-form-model-item
                      :key="item.key1"
                      label=""
                      :prop="'userDepartmentList.' + index + '.departmentId'"
                      :colon="false"
                    >
                      <!--:rules="{ required: true, message: '请选择部门', trigger: 'change' }"-->
                      <a-select v-model="item.departmentId">
                        <a-select-option v-for="element in deptList" :key="element.departmentId" :value="element.departmentId">
                          {{ element.departmentName }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="7">
                    <a-form-model-item
                      :key="item.key2"
                      :prop="'userDepartmentList.' + index + '.isManage'"
                      :colon="false"
                      class="manage-list"
                      :rules="{ required: true, message: '请选择职位', trigger: 'change' }"
                    >
                      <a-select v-model="item.isManage" class="mr-10">
                        <a-select-option v-for="element in manageList" :key="element.manageId" :value="element.manageId">
                          {{ element.manageName }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="1">
                    <a-icon type="delete" style="color: #f5222d" @click="deleteDepartment(index)" />
                  </a-col>
                </div>
              </a-form-model-item>
            </template>
            <a-button type="dashed" style="margin-left: 60px" @click="addDepartment()">
              <a-icon type="plus-circle" theme="filled" />
              添加部门/职位
            </a-button>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { useAdd, uploadUserPortrait } from '@/api/user'
function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'MemberAdd',
  props: {
    addVisible: {
      type: Boolean,
      default: false
    },
    roleList: {
      type: Array,
      default: () => []
    },
    deptList: {
      type: Array,
      default: () => []
    },
    parentData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    const validateTel = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        const reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        if (!reg_tel.test(value)) {
          callback(new Error('手机号格式错误，请正确输入手机号'))
        }
        callback()
      }
    }
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入邮箱'))
      } else {
        const reg_Email = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
        if (!reg_Email.test(value)) {
          callback(new Error('邮箱格式错误，请正确输入邮箱'))
        }
        callback()
      }
    }
    return {
      loading: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      imgLoading: false,
      imageUrl: '',
      url: '',
      backupsData: {},
      rules: {
        userName: [{ required: true, message: '请输入用户名称', trigger: 'blur' }],
        nickName: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        email: [{ validator: validateEmail, trigger: 'blur' }],
        // password: [{ required: true, message: '请输入初始登录密码', trigger: 'blur' }],
        tel: [{ validator: validateTel, trigger: 'blur' }],
        // landline: [{ required: true, message: '请输入座机号', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }]
        // isEnable: [{ required: true, message: '请选择启用状态', trigger: 'change' }],
        // userCode: [{ required: true, message: '输入工号', trigger: 'blur' }],
        // address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        // remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        // userRole: [{ required: true, message: '请选择角色', trigger: 'change' }]
      },
      formData: {
        userCode: '',
        enterpriseId: this.parentData.enterpriseId,
        nickName: '',
        password: '',
        portraitFileCode: '',
        isEnable: 1,
        tel: '',
        landline: '',
        sex: 0,
        address: '',
        remark: '',
        userDepartmentList: [],
        userRole: []
      },
      defaultValue: [],
      manageList: [
        { manageId: 0, manageName: '成员' },
        { manageId: 1, manageName: '主管' }
      ],
      isUpload: false,
      file: {},
      fileData: {}
    }
  },
  created() {
    this.backupsData = {...this.formData}
  },
  mounted() {
    if (this.formData.userRole.length < 1) {
      this.defaultValue = []
      return
    }
    this.defaultValue = this.formData.userRole.map((item) => {
      return {
        key: item.roleId,
        label: item.roleName
      }
    })
  },
  methods: {
    handleCancel() {
      this.$emit('addCloseChange', false)
    },
    // 保存编辑
    saveEdit(ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          this.loading = true
          const formData = {...this.formData}
          formData.userDepartmentList = formData.userDepartmentList.map((value) => {
            return {
              departmentId: value.departmentId,
              isManage: value.isManage
            }
          })
          this.useAdd(formData)
        } else {
          return false
        }
      })
    },
    useAdd(formData) {
      formData.userRole = formData.userRole.map((value) => {
        return { roleId: parseInt(value.key) }
      })
      useAdd(formData).then((res) => {
        this.loading = false
        if (res['code'] !== 0) {
          return
        }
        this.$antMessage.success(res['message'])
        this.$emit('addMemberSuccess', false)
      })
    },
    // 取消编辑
    cancelEdit(ruleForm) {
      this.$refs[ruleForm].resetFields()
      this.formData = {...this.backupsData}
      this.$emit('addCloseChange', false)
    },
    // 删除部门/职位
    deleteDepartment(index) {
      this.formData.userDepartmentList.splice(index, 1)
    },
    // 添加部门/职位
    addDepartment() {
      this.formData.userDepartmentList.push(
        { departmentId: '', isManage: '', key1: Date.now(), key2: Date.now() + 1 }
      )
      this.$forceUpdate()
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.imgLoading = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl
          this.imgLoading = false
        })
      }
    },
    // 上传前
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$antMessage.error('您只能上传 JPG 或 png 文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$antMessage.error('上传图片不能超过5M!')
        return false
      }
      this.file = file
      this.isUpload = true
    },
    // 上传文件
    customRequest() {
      this.imgLoading = true
      const data = new FormData()
      data.append('file', this.file)
      data.append('userId', '')
      data.append('enterpriseId', '')
      uploadUserPortrait(data).then((res) => {
        this.imgLoading = false
        if (res['code'] !== 0) {
          this.$antMessage.error(['message'])
          return
        }
        this.fileData = res.data
        this.formData.portraitFileCode = res.data.fileCode
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.img {
  border-radius: 30px;
}

.w-40-20 {
  width: calc(40% - 20px);
}

.w-60-20 {
  width: calc(60% - 20px);
}

.modal-content {
  .department-list-item {
    display: flex;
    justify-content: space-between;

    .list-item-text {
      width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .list-item-role {
      width: 40px;
    }
  }

  &.unEdit {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

::v-deep .ant-modal-wrap.add-member {
  .ant-modal-body {
    max-height: 65vh;
    overflow-y: auto;

    .ant-form {
      .ant-form-item {
        width: 100%;

        .ant-form-item-label {
          width: 60px;
        }

        .ant-form-item-control-wrapper {
          width: calc(100% - 70px);
        }

        &.ant-form-item-with-help {
          margin-bottom: 0;
        }
        &.department-list{
          .department-list-item {
            .ant-form-item-control-wrapper{
              width: 100%;
            }
          }
        }
        .manage-list{
          .ant-form-item-control-wrapper {
            width: calc(100% - 20px);
          }
        }
      }
    }
  }
}
.w-100{
  width: 200px;
}
</style>
