import axios from '@/utils/request'

/**
 * 用户基础信息
 * @data {*} data
 */
export function userBaseInfo(data) {
  return axios({
    url: '/user/base-info',
    method: 'post',
    data
  })
}

/**
 * 用户信息
 * @data {*} data
 */
export function userInfo(data) {
  return axios({
    url: '/user/info',
    method: 'post',
    data
  })
}

/**
 * 删除用户
 * @data {*} data
 */
export function userDelete(data) {
  return axios({
    url: '/user/enterprise/delete',
    method: 'post',
    data
  })
}

/**
 * 获取用户数量
 * @data {*} data
 */
export function userAmount(data) {
  return axios({
    url: '/user/count',
    method: 'post',
    data
  })
}

/**
 * 部门基础信息
 * @data {*} data
 */
export function departmentBases(data) {
  return axios({
    url: '/department/bases',
    method: 'post',
    data
  })
}

/**
 * 企业部门列表
 * @data {*} data
 */
export function departmentList(data) {
  return axios({
    url: '/department/tree',
    method: 'post',
    data
  })
}

/**
 * 部门用户列表
 * @data {*} data
 */
export function deptUserList(data) {
  return axios({
    url: '/user/list',
    method: 'post',
    data
  })
}

/**
 * 添加成员--全部成员（不带分页）
 * @data {*} data
 */
export function bookUserList(data) {
  return axios({
    url: '/user/list',
    method: 'post',
    data
  })
}

/**
 * 添加成员--部门列表（不带分页）
 * @data {*} data
 */
export function commonDeptList(data) {
  return axios({
    url: '/department/list',
    method: 'post',
    data
  })
}

/**
 * 添加部门成员
 * @data {*} data
 */
export function userDepartmentsAdd(data) {
  return axios({
    url: '/user/department/batch-add',
    method: 'post',
    data
  })
}

/**
 * 添加部门成员
 * @data {*} data
 */
export function userRolesAdd(data) {
  return axios({
    url: '/user/role/batch-add',
    method: 'post',
    data
  })
}

/**
 * 删除部门
 * @data {*} data
 */
export function departmentDelete(data) {
  return axios({
    url: '/department/delete',
    method: 'post',
    data
  })
}

/**
 * 新增部门
 * @data {*} data
 */
export function departmentAdd(data) {
  return axios({
    url: '/department/add',
    method: 'post',
    data
  })
}

/**
 * 编辑部门
 * @data {*} data
 */
export function departmentUpdate(data) {
  return axios({
    url: '/department/modify',
    method: 'post',
    data
  })
}

/**
 * 移除部门中用户
 * @data {*} data
 */
export function moveDepartmentUser(data) {
  return axios({
    url: '/user/department/batch-delete',
    method: 'post',
    data
  })
}

/**
 * 部门主管列表
 * @data {*} data
 */
export function departmentManagerList(data) {
  return axios({
    url: '/department/manager/list',
    method: 'post',
    data
  })
}

/**
 * 部门主管列表
 * @data {*} data
 */
export function setPosition(data) {
  return axios({
    url: '/user/department/manager/set',
    method: 'post',
    data
  })
}

/**
 * 获取角色列表
 * @data {*} data
 */
export function roleList(data) {
  return axios({
    url: '/role/list',
    method: 'post',
    data
  })
}

/**
 * 编辑角色
 * @data {*} data
 */
export function roleUpdate(data) {
  return axios({
    url: '/role/update',
    method: 'post',
    data
  })
}

/**
 * 新增角色
 * @data {*} data
 */
export function roleAdd(data) {
  return axios({
    url: '/role/add',
    method: 'post',
    data
  })
}

/**
 * 删除角色
 * @data {*} data
 */
export function roleDelete(data) {
  return axios({
    url: '/role/delete',
    method: 'post',
    data
  })
}

/**
 * 角色用户列表
 * @data {*} data
 */
export function roleUserList(data) {
  return axios({
    url: '/user/list/by-role',
    method: 'post',
    data
  })
}

/**
 * 移除职务中的用户
 * @data {*} data
 */
export function moveRoleUser(data) {
  return axios({
    url: '/user/role/batch-delete',
    method: 'post',
    data
  })
}

/**
 * 角色
 * @data {*} data
 */
export function userRole(data) {
  return axios({
    url: '/set/user/role',
    method: 'post',
    data
  })
}

/**
 * 新增用户
 * @data {*} data
 */
export function useAdd(data) {
  return axios({
    url: '/user/add',
    method: 'post',
    data
  })
}

/**
 * 编辑用户
 * @data {*} data
 */
export function userUpdate(data) {
  return axios({
    url: '/user/update',
    method: 'post',
    data
  })
}

/**
 * 用户禁用
 * @data {*} data
 */
export function useDisable(data) {
  return axios({
    url: '/user/disable',
    method: 'post',
    data
  })
}

/**
 * 用户详情
 * @data {*} data
 */
export function useDetail(data) {
  return axios({
    url: '/user/detail',
    method: 'post',
    data
  })
}

/**
 * 修改密码
 * @data {*} data
 */
export function userPasswordUpdate(data) {
  return axios({
    url: '/user/password/update',
    method: 'post',
    data
  })
}

/**
 * 重置密码
 * @data {*} data
 */
export function resetPassword(data) {
  return axios({
    url: '/user/reset/password',
    method: 'post',
    data
  })
}

/**
 * 图片上传
 * @data {*} data
 */
export function uploadUserPortrait(data) {
  return axios({
    url: '/user/upload/portrait',
    method: 'post',
    data
  })
}

/**
 * 修改用户头像
 * @data {*} data
 */
export function modifyUserPortrait(data) {
  return axios({
    url: '/user/portrait/modify',
    method: 'post',
    data
  })
}


/**
 * 向管理员列表中增加用户
 * @data {*} data
 */
export function enterpriseManagerAdd(data) {
  return axios({
    url: '/enterprise/manager/add',
    method: 'post',
    data
  })
}

/**
 * 从管理员列表移除用户
 * @data {*} data
 */
export function enterpriseManagerRemove(data) {
  return axios({
    url: '/enterprise/manager/remove',
    method: 'post',
    data
  })
}
