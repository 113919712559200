<template>
  <a-modal
    title="权限"
    :visible="permissionsVisible"
    :confirm-loading="confirmLoading"
    ok-text="保存"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-tree
      v-model="checkedKeys"
      checkable
      :expanded-keys="expandedKeys"
      :auto-expand-parent="autoExpandParent"
      :selected-keys="selectedKeys"
      :tree-data="treeData"
      :check-strictly="true"
      @expand="onExpand"
      @select="onSelect"
    />
  </a-modal>

</template>

<script>
const treeData = [
  {
    title: '0-0',
    key: '0-0',
    children: [
      {
        title: '0-0-0',
        key: '0-0-0',
        children: [
          { title: '0-0-0-0', key: '0-0-0-0' },
          { title: '0-0-0-1', key: '0-0-0-1' },
          { title: '0-0-0-2', key: '0-0-0-2' }
        ]
      },
      {
        title: '0-0-1',
        key: '0-0-1',
        children: [
          { title: '0-0-1-0', key: '0-0-1-0' },
          { title: '0-0-1-1', key: '0-0-1-1' },
          { title: '0-0-1-2', key: '0-0-1-2' }
        ]
      },
      {
        title: '0-0-2',
        key: '0-0-2'
      }
    ]
  },
  {
    title: '0-1',
    key: '0-1',
    children: [
      { title: '0-1-0-0', key: '0-1-0-0' },
      { title: '0-1-0-1', key: '0-1-0-1' },
      { title: '0-1-0-2', key: '0-1-0-2' }
    ]
  },
  {
    title: '0-2',
    key: '0-2'
  }
]

export default {
  name: 'Permissions',
  props: {
    permissionsVisible: {
      type: Boolean,
      default: false
    },
    permissionsTreeData: {
      type: Object || Array,
      default: () => []
    }
  },
  data() {
    return {
      expandedKeys: ['0-0-0', '0-0-1'],
      autoExpandParent: true,
      checkedKeys: ['0-0-0'],
      selectedKeys: [],
      treeData,
      confirmLoading: false
    }
  },
  watch: {
    // checkedKeys(val) {
    // }
  },
  methods: {
    onExpand(expandedKeys) {
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onSelect(selectedKeys) {
      this.selectedKeys = selectedKeys
    },
    handleOk() {
      this.confirmLoading = true
      setTimeout(() => {
        this.$emit('closePermissions', false)
        this.confirmLoading = false
      }, 2000)
    },
    handleCancel() {
      this.$emit('closePermissions', false)
    }
  }
}
</script>

<style scoped>

</style>
