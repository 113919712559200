<template>
  <div>
    <a-modal
      title="成员信息"
      :visible="detailVisible"
      :mask-closable="false"
      :cancel-text="null"
      width="30%"
      wrap-class-name="add-member"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button v-if="isEdit" key="back" @click="cancelEdit('ruleForm')">
          取消
        </a-button>
        <a-button v-if="isEdit" key="submit" type="primary" :loading="loading" @click="saveEdit('ruleForm')">
          保存
        </a-button>
        <!--<a-button v-if="!isEdit" key="submit" type="primary" @click="isEdit = true">-->
        <a-button v-if="!isEdit" key="submit" type="primary" @click="editMember">
          编辑
        </a-button>
      </template>
      <div v-if="!isEdit" class="modal-content unEdit">
        <div class="img">
          <!--<img :src="require('@/' + detailData.url)" width="60" height="60" class="img" alt="">-->
        </div>
        <a-form-model
          ref="ruleForm"
          :model="detailData"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          label-align="right"
          layout="inline"
        >
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="头像">
                <a-avatar :size="50" shape="square" icon="user" :src="detailData.portraitFileUrl ? detailData.portraitFileUrl : undefined" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <!--<a-col :span="12">-->
            <!--  <a-form-model-item label="名称" prop="userName">{{ detailData.userName }}</a-form-model-item>-->
            <!--</a-col>-->
            <a-col :span="12">
              <a-form-model-item label="昵称">{{ detailData.nickName }}</a-form-model-item>
            </a-col>
          <!--</a-row>-->
          <!--<a-row>-->
          <!--  <a-col :span="12">-->
          <!--    <a-form-model-item label="邮箱" prop="email">{{ detailData.email }}</a-form-model-item>-->
          <!--  </a-col>-->
            <a-col :span="12">
              <a-form-model-item label="工号">{{ detailData.userCode }}</a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="手机">{{ detailData.tel }}</a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="座机">{{ detailData.landline }}</a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="性别">{{ detailData.sex === 0? '男' : '女' }}</a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="状态">{{ detailData.isEnable === 0? '禁用' : '启用' }}</a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="地址">{{ detailData.address }}</a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="备注">{{ detailData.remark }}</a-form-model-item>
            </a-col>
          </a-row>
          <a-divider />
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="角色">
                <div v-for="item in detailData.userRole" :key="item.roleId">
                  {{ roleTypeList[item] }}
                </div>
                <!--<div v-for="(item in roleTypeList" :key="item.roleId">-->
                <!--  <span v-if="">{{ item.roleName }}</span>-->
                <!--</div>-->
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <!--<a-col :span="24">-->
            <template v-if="detailData.userDepartmentList && detailData.userDepartmentList.length > 0">
              <a-form-model-item
                v-for="(item, index) in detailData.userDepartmentList"
                :key="item.departmentId"
                :label="index === 0 ?'部门': ' '"
                :colon="index === 0"
                class="department-list"
              >
                <div class="department-list-item">
                  <a-col :span="15">
                    {{ item.departmentName }}
                  </a-col>
                  <a-col :span="9">
                    {{ item.isManage === 0? '成员' : '主管' }}
                  </a-col>
                </div>
              </a-form-model-item>
            </template>
            <template v-else>
              <a-form-model-item label="部门">
                - 无 -
              </a-form-model-item>
            </template>
          </a-row>
        </a-form-model>
      </div>
      <div v-else class="modal-content edit">
        <a-form-model
          ref="ruleForm"
          :model="editData"
          :rules="rules"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          label-align="right"
          layout="inline"
        >
          <a-row>
            <a-col :span="24">
              <a-form-model-item ref="userName" label="头像" prop="fileUrl">
                <a-upload
                  name="file"
                  accept="image"
                  :show-upload-list="false"
                  :before-upload="beforeUpload"
                  :custom-request="customRequest"
                  class="mr-10"
                >
                  <a-avatar v-if="isUpload"  icon="user" :loading="imgLoading" :size="50" shape="square" :src="fileData.fileUrl" />
                  <a-avatar v-else :size="50"  icon="user" :loading="imgLoading" shape="square" :src="editData.portraitFileUrl ? editData.portraitFileUrl : undefined" />
                </a-upload>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <!--<a-col :span="12">-->
            <!--  <a-form-model-item ref="userName" label="名称" prop="userName">-->
            <!--    <a-input v-model="detailData.userName" @blur="() => { $refs.userName.onFieldBlur() }" />-->
            <!--  </a-form-model-item>-->
            <!--</a-col>-->
            <a-col :span="12">
              <a-form-model-item ref="nickName" label="昵称" prop="nickName">
                <a-input v-model="editData.nickName" @blur="() => { $refs.nickName.onFieldBlur() }" />
              </a-form-model-item>
            </a-col>
          <!--</a-row>-->
          <!--<a-row>-->
          <!--  <a-col :span="12">-->
          <!--    <a-form-model-item ref="email" label="邮箱" prop="email">-->
          <!--      {{ detailData.email }}-->
          <!--    </a-form-model-item>-->
          <!--  </a-col>-->
            <a-col :span="12">
              <a-form-model-item ref="userCode" label="工号" prop="userCode">
                <a-input v-model="editData.userCode" @blur="() => { $refs.userCode.onFieldBlur() }" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item ref="tel" label="手机" prop="tel">
                {{ detailData.tel }}
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item ref="landline" label="座机" prop="landline">
                <a-input v-model="editData.landline" @blur="() => { $refs.landline.onFieldBlur() }" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="性别" prop="sex">
                <a-radio-group v-model="editData.sex">
                  <a-radio :value="0">男</a-radio>
                  <a-radio :value="1">女</a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <!--<a-col :span="12">-->
            <!--  <a-form-model-item label="状态" prop="isEnable">-->
            <!--    <a-radio-group v-model="detailData.isEnable">-->
            <!--      <a-radio :value="1">启用</a-radio>-->
            <!--      <a-radio :value="0">禁用</a-radio>-->
            <!--    </a-radio-group>-->
            <!--  </a-form-model-item>-->
            <!--</a-col>-->
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="地址" prop="address">
                <a-input v-model="editData.address" type="textarea" :rows="2" style="resize:none" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="备注" prop="remark">
                <a-input v-model="editData.remark" type="textarea" :rows="3" style="resize:none" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-divider />
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="角色" >
                <!--prop="userRole"-->
                <a-select
                  v-model="editData.userRole"
                  mode="tags"
                  placeholder="角色"
                  :default-value="editData.userRole"
                >
                  <!--:label-in-value="true"-->
                  <a-select-option v-for="item in roleList" :key="item.roleId.toString()">
                    {{ item.roleName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <template v-if="editData.userDepartmentList && editData.userDepartmentList.length === 0">
              <a-form-model-item label="部门"  prop="userDepartmentList">
                - -
              </a-form-model-item>
            </template>
            <template v-else>
              <a-form-model-item
                v-for="(item, index) in editData.userDepartmentList"
                :key="index"
                :label="index === 0 ?'部门': ' '"
                :colon="index === 0"
                class="department-list"
              >
                <div class="department-list-item">
                  <a-col :span="15">
                    <a-form-model-item
                      :key="item.key1"
                      label=""
                      :prop="'userDepartmentList.' + index + '.departmentId'"
                      :colon="false"
                      :rules="{ required: true, message: '请选择部门', trigger: 'change' }"
                    >
                      <a-select v-model="item.departmentId">
                        <a-select-option v-for="element in deptList" :key="element.departmentId" :value="element.departmentId">
                          {{ element.departmentName }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="7">
                    <a-form-model-item
                      :key="item.key2"
                      :prop="'userDepartmentList.' + index + '.isManage'"
                      :colon="false"
                      class="manage-list"
                      :rules="{ required: true, message: '请选择职位', trigger: 'change' }"
                    >
                      <a-select v-model="item.isManage" class="mr-10">
                        <a-select-option v-for="element in manageList" :key="element.manageId" :value="element.manageId">
                          {{ element.manageName }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="1">
                    <a-icon type="delete" style="color: #f5222d" @click="deleteDepartment(index)" />
                  </a-col>
                </div>
              </a-form-model-item>
            </template>
            <a-button type="dashed" style="margin-left: 60px" @click="addDepartment()">
              <a-icon type="plus-circle" theme="filled" />
              添加部门/职位
            </a-button>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { userUpdate, uploadUserPortrait } from '@/api/user'

export default {
  name: 'MemberDetail',
  props: {
    detailVisible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    detailData: {
      type: Object,
      default: () => {}
    },
    roleTypeList: {
      type: Object,
      default: () => {}
    },
    roleList: {
      type: Array,
      default: () => []
    },
    parentData: {
      type: Object,
      default: () => {}
    },
    deptList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      imgLoading: false,
      imageUrl: '',
      // isEdit: false,
      url: '',
      editData: {},
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      positionData: {
        '0': '主管',
        '1': '成员'
      },
      rules: {
        // userName: [{ required: true, message: '请输入用户名称', trigger: 'blur' }],
        nickName: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        // landline: [{ required: true, message: '请输入座机号', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        // isEnable: [{ required: true, message: '请选择启用状态', trigger: 'change' }],
        // userCode: [{ required: true, message: '输入工号', trigger: 'blur' }],
        // address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        // remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        // userRole: [{ required: true, message: '请选择角色', trigger: 'change' }]
      },
      manageList: [
        { manageId: 0, manageName: '成员' },
        { manageId: 1, manageName: '主管' }
      ],
      defaultValue: [],
      formData: {
        userId: '',
        userCode: '',
        enterpriseId: this.parentData.enterpriseId,
        userName: '',
        email: '',
        password: '',
        nickName: '',
        portraitFileCode: '',
        isEnable: '',
        tel: '',
        landline: '',
        sex: '',
        address: '',
        remark: '',
        userDepartmentList: [],
        userRole: [],
      },
      isUpload: false,
      file: {},
      fileData: {
        fileCode: null
      }
    }
  },
  methods: {
    handleCancel() {
      this.$emit('changeEdit', false)
      this.$emit('openDetail', false)
    },
    // 打开编辑
    editMember() {
      this.$emit('editMember')
      this.editData = {...this.detailData}
      this.$emit('changeEdit', true)
    },
    // 保存编辑
    saveEdit(ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          this.loading = true
          Object.keys(this.editData).forEach(key => {
            // eslint-disable-next-line no-prototype-builtins
            if (this.formData.hasOwnProperty(key)) {
              this.formData[key] = this.editData[key]
            }
          })
          this.formData.portraitFileCode = this.fileData.fileCode || null
          this.formData.userDepartmentList = this.formData.userDepartmentList.map((value) => {
            return {
              departmentId: value.departmentId,
              isManage: value.isManage
            }
          })
          this.formData.userRole = this.formData.userRole.map((value) => {
            return { roleId: value }
          })
          userUpdate(this.formData).then((res) => {
            this.loading = false
            if (res['code'] !== 0) {
              this.$antMessage.error(res['message'])
              return
            }
            this.isUpload = false
            this.$antMessage.success(res['message'])
            this.$emit('editSuccess', this.formData.userId)
            this.$emit('changeEdit', false)
          })
        } else {
          return false
        }
      })
    },
    // 取消编辑
    cancelEdit(ruleForm) {
      this.$refs[ruleForm].resetFields()
      this.isUpload = false
      this.$emit('changeEdit', false)
    },
    // 删除部门/职位
    deleteDepartment(index) {
      this.detailData.userDepartmentList.splice(index, 1)
    },
    // 添加部门/职位
    addDepartment() {
      this.detailData.userDepartmentList.push(
        { departmentId: '', isManage: '', key1: Date.now(), key2: Date.now() + 1 }
      )
    },
    // 上传前
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$antMessage.error('您只能上传 JPG 或 png 文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$antMessage.error('上传图片不能超过5M!')
        return false
      }
      this.file = file
      this.isUpload = true
    },
    // 上传文件
    customRequest() {
      this.imgLoading = true
      const data = new FormData()
      data.append('file', this.file)
      data.append('userId', '')
      data.append('enterpriseId', '')
      uploadUserPortrait(data).then((res) => {
        this.imgLoading = false
        if (res['code'] !== 0) {
          this.$antMessage.error(['message'])
          return
        }
        this.fileData = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.img{
  border-radius: 30px;
}
.w-120{
  width: 120px;
}
.w-160{
  width: 160px;
}
::v-deep .ant-modal-body{
  max-height: 65vh;
  overflow-y: auto;
  .modal-content{
    .department-list-item{
      display: flex;
      justify-content: space-between;
      .list-item-text{
        width:300px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      .list-item-role{
        width: 40px;
      }
    }
    &.unEdit{
      .ant-form-item{
        margin-bottom: 0;
      }
    }
  }
}
.modal-content {
  .department-list-item {
    display: flex;
    justify-content: space-between;

    .list-item-text {
      width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .list-item-role {
      width: 40px;
    }
  }

  &.unEdit {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
::v-deep .ant-modal-wrap.add-member {
  .ant-modal-body {
    max-height: 65vh;
    overflow-y: auto;

    .ant-form {
      .ant-form-item {
        width: 100%;

        .ant-form-item-label {
          width: 60px;
        }

        .ant-form-item-control-wrapper {
          width: calc(100% - 70px);
        }

        &.ant-form-item-with-help {
          margin-bottom: 0;
        }
        &.department-list{
          .department-list-item {
            .ant-form-item-control-wrapper{
              width: 100%;
            }
          }
        }
        .manage-list{
          .ant-form-item-control-wrapper {
            width: calc(100% - 20px);
          }
        }
      }
    }
  }
}
</style>
