<template>
  <div class="tree-container">
    <!--    <a-row :gutter="16" class="container-row">-->
    <!--      <a-col class="gutter-row left" :span="6">-->
    <a-directory-tree :tree-data="treeData" :expand-action="false" :replace-fields="replaceFields" :icon="getIcon" :show-icon="true" :default-expand-all="true" :selected-keys="selectedKeys" @select="selectNode" />
    <!-- 右侧图标 -->
    <!--    <a-tree :tree-data="treeData" default-expand-all :selected-keys="selectedKeys" :block-node="true" @select="selectNode">-->
    <!--      <template slot="custom" slot-scope="item">-->
    <!--        <div class="node-title"><a-icon class="mr-5" :type="item.type" />{{ item.title }} </div>-->
    <!--        <span class="node-edit">-->
    <!--          <span class="icon-wrap">-->
    <!--            <a-icon class="theme" type="plus-circle" @click="operationNode(item, 1)" />-->
    <!--          </span>-->
    <!--          <span class="icon-wrap">-->
    <!--            <a-icon class="theme" type="form" @click="operationNode(item,2)" />-->
    <!--          </span>-->
    <!--          <span class="icon-wrap">-->
    <!--            <a-icon class="theme" type="delete" :disabled="item.departmentId=='0-0'" :class="item.departmentId=='0-0'?'disable':''" @click="operationNode(item, 3)" />-->
    <!--          </span>-->
    <!--        </span>-->
    <!--      </template>-->
    <!--    </a-tree>-->
    <!-- 右键菜单 -->
    <!--      </a-col>-->
    <!--      <a-col class="gutter-row right" :span="18">-->
    <!--        <div style="margin-bottom: 16px">-->
    <!--          <a-button type="primary" :disabled="!hasSelected" :loading="loading" @click="start">-->
    <!--            Reload-->
    <!--          </a-button>-->
    <!--          <span style="margin-left: 8px">-->
    <!--            <template v-if="hasSelected">-->
    <!--              {{ `Selected ${selectedRowKeys.length} items` }}-->
    <!--            </template>-->
    <!--          </span>-->
    <!--        </div>-->
    <!--        <div />-->
    <!--        <a-table-->
    <!--          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"-->
    <!--          :columns="columns"-->
    <!--          :data-source="data"-->
    <!--        />-->
    <!--      </a-col>-->
    <!--    </a-row>-->
    <!--    <a-modal-->
    <!--      :title="Title"-->
    <!--      :visible="visible"-->
    <!--      :confirm-loading="confirmLoading"-->
    <!--      ok-text="确认"-->
    <!--      cancel-text="取消"-->
    <!--      @ok="(e) => handleOk(e, 'ruleForm')"-->
    <!--      @cancel="(e) => handleCancel(e,'ruleForm')"-->
    <!--    >-->
    <!--      <a-form-model-->
    <!--        ref="ruleForm"-->
    <!--        :model="form"-->
    <!--        :rules="rules"-->
    <!--        :label-col="labelCol"-->
    <!--        :wrapper-col="wrapperCol"-->
    <!--      >-->
    <!--        <a-form-model-item ref="name" label="名称" prop="name">-->
    <!--          <a-input-->
    <!--            v-model="form.name"-->
    <!--            @blur="-->
    <!--              () => {-->
    <!--                $refs.name.onFieldBlur();-->
    <!--              }-->
    <!--            "-->
    <!--          />-->
    <!--        </a-form-model-item>-->
    <!--      </a-form-model>-->
    <!--    </a-modal>-->
  </div>
</template>

<script>
const columns = [
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Age',
    dataIndex: 'age'
  },
  {
    title: 'Address',
    dataIndex: 'address'
  }
]

const data = []
for (let i = 0; i < 9; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`
  })
}
export default {
  name: 'Department',
  props: {
    selectedKeys: {
      type: Array,
      default: () => []
    },
    treeData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // treeData: [],
      expandedKeys: ['0-0-0', '0-0-1'],
      visible: false,
      confirmLoading: false,
      Title: '',
      form: {
        name: ''
      },
      rules: {
        name: [{ required: true, message: '请输入部门名称', trigger: 'blur' }]
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      data,
      columns,
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      replaceFields: {
        children: 'list',
        title: 'departmentName',
        key: 'departmentId'
      }
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  watch: {
    // expandedKeys(value) {
    // },
    // treeData: {
    //   immediate: true,
    //   handler(value) {
    //   }
    // }
  },
  mounted() {
  },
  methods: {
    // // 右键点击
    // rightClick({ event, node }) {
    // },
    // 选中节点
    selectNode(selectedKeys, { node }) {
      // this.setTreeData(selectedKeys)
      // this.simulateSetData(this.falseData, selectedKeys)
      this.$emit('getDetail', node.dataRef)
      this.$emit('changeSelectedKeys', selectedKeys)
    },
    // // 操作节点
    // operationNode(item, type) {
    //   switch (type) {
    //     case 1:
    //       this.Title = '添加'
    //       this.visible = true
    //       break
    //     case 2:
    //       this.Title = '编辑'
    //       this.visible = true
    //       break
    //     case 3:
    //       this.Modal.confirm('删除该部门时，会连同其下所有部门都一起删除, 你确定要删除这个部门吗?', '提示', {
    //         confirmButtonText: '确定',
    //         cancelButtonText: '取消',
    //         type: 'warning'
    //       }).then(() => {
    //         this.$antMessage({
    //           type: 'success',
    //           message: '删除成功!'
    //         })
    //       }).catch(() => {
    //         this.$antMessage({
    //           type: 'info',
    //           message: '已取消删除'
    //         })
    //       })
    //
    //       break
    //     default:
    //   }
    // },
    handleOk(e, ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          this.confirmLoading = true
          setTimeout(() => {
            this.visible = false
            this.confirmLoading = false
          }, 2000)
        } else {
          return false
        }
      })
    },
    handleCancel(e, ruleForm) {
      this.$refs[ruleForm].resetFields()
      this.visible = false
    },
    start() {
      this.loading = true
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    getIcon() {
      // const { type } = props
      const type = 'edit'
      // const { type } = 'form'
      return <a-icon type={type} />
    }

  }
}
</script>

<style lang="scss" scoped>
.tree-container{
  //height: calc( 100% - 100px);
  height: 467px;
  overflow-y: auto;
  .container-row{
    height: 100%;
  }
  .left{
    max-width: 300px;
    min-width: 200px;
    height: 100%;
    border: 1px solid #e9e9e9;
    margin-bottom: 20px;

  }
  // 当 树组件是a-tree使用此样式，选中节点背景色通栏
  //::v-deep .ant-tree-child-tree > li,::v-deep .ant-tree > li{
  //  position: relative;
  //}
  .theme{
    color: #1890ff;
  }
  .disable{
    color: #00000040;
  }
  .icon-wrap{
    margin: 0 5px;
  }
  .ant-tree{
    text-align: left;
  }
}

.node-edit{
  position: absolute;
  right: 0;
  top: 8px;
}
</style>
