<template>
  <div class="list-container">
    <div class="list-container-header mb-10">
      <div class="title">
        <span>
          <span v-if="listType === 'member'">
            <span>{{ parentData.memberTypeName }}</span>
            <span v-if="parentData.memberTypeName"> ({{ pagination.total }})</span>
          </span>
          <span v-if="listType === 'department'">
            <span>{{ parentData.departmentName }}</span>
            <span v-if="parentData.departmentName">({{ pagination.total }})</span>
          </span>
          <span v-if="listType === 'role'">
            <span>{{ parentData.roleName }}</span>
            <span v-if="parentData.roleName"> ({{ pagination.total }})</span>
          </span>
        </span>
      </div>
      <div>
        <a-dropdown v-if="listType !== 'member'" class="mr-10">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            <a-icon type="ellipsis" />更多
          </a>
          <a-menu v-if="listType==='department'" slot="overlay">
            <a-menu-item>
              <a href="javascript:" @click="editDepartment(parentData)"><a-icon type="edit" />编辑部门</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:" @click="deleteDepartment(parentData.departmentId)"><a-icon type="delete" />删除部门</a>
            </a-menu-item>
          </a-menu>
          <a-menu v-if="listType==='role'" slot="overlay">
            <a-menu-item>
              <a href="javascript:" @click="editRole()"><a-icon type="edit" />编辑角色</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:" @click="deleteRole()"><a-icon type="delete" />删除角色</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-button v-if="listType==='department'" type="dashed" class="mr-10" @click="addChildren(parentData)"><a-icon type="plus-circle" theme="filled" />添加子部门</a-button>
        <!--<a-button type="default" class="mr-10">导出</a-button>-->
        <!--<a-button type="primary" class="mr-10">同步成员</a-button>-->
        <MemberModal
          v-if="memberVisible && listType !== 'member'"
          ref="memberModal"
          :modal-type="listType"
          :visible="memberVisible"
          :parentData="parentData"
          :placement="'bottomRight'"
          @addMember="addMember"
        />
      </div>
    </div>
    <div>
      <div v-if="isChecked" class="m-10-0" style="text-align: left">
        <!--<a-button v-if="listType === 'member'" type="default" class="mr-10">删除选中</a-button>-->
        <a-button v-if="listType !== 'member'" type="default" class="mr-10" @click="shiftOut('batch')">移出选中</a-button>
        <a-button v-if="listType !== 'member'" type="danger" class="mr-10" @click="shiftOut('all')">移出全部</a-button>
        <a-button type="default" class="mr-10" @click="changeStatus(1, selectedRowKeys)">
          <a-icon type="play-circle" theme="filled" class="enable" />启用
        </a-button>
        <a-button type="default" class="mr-10" @click="changeStatus(0, selectedRowKeys)">
          <a-icon type="minus-circle" theme="filled" class="disable" />禁用
        </a-button>
      </div>
      <!--:data-source="listType === 'department' ? listData.list : listData.userList"-->
      <a-table
        :columns="columns"
        :data-source="listData.list"
        row-key="userId"
        :row-class-name="rowClassName"
        :row-selection="{
          columnWidth: '50px',
          selectedRowKeys: selectedRowKeys,
          onChange: onRowChange,
          onSelect: onRowSelect,
          onSelectAll: onRowSelectAll
        }"
        :custom-row="rowClick"
        :locale="localeText"
        :pagination="pagination"
        @change="onChange"
      >
        <!--        :locale="localeText"-->
        <span slot="name" slot-scope="text">{{ text }}</span>
        <span slot="enterpriseManage" slot-scope="text">{{ text === 1 ? '是' : '否' }}</span>
        <span v-show="listType === 'member' || listType === 'role'" slot="departmentList" slot-scope="departmentList">
          <a-tooltip>
            <template slot="title">
              <template v-for="(item, index) in departmentList">
                <span :key="index">{{ item.departmentName }} <span v-if="index !== departmentList.length-1" class="m-0-5"> | </span></span>
              </template>
            </template>
            <template v-for="(item, index) in departmentList">
              <span :key="index">{{ item.departmentName }} {{ index }} <span v-if="index !== departmentList.length-1" class="m-0-5"> | </span></span>
            </template>
          </a-tooltip>
        </span>
        <span v-show="listType === 'department'" slot="isManage" slot-scope="isManage, record">
          {{ positionData[isManage] }}
          <template>
            <a-dropdown :trigger="['click']">
              <a class="ant-dropdown-link" @click="e => e.preventDefault()"><a-icon type="down" /></a>
              <a-menu slot="overlay">
                <a-menu-item v-for="(item, key, i) in positionData" :key="i" style="width: 100px">
                  <div @click="clickMenu(record, key, isManage)">
                    {{ item }}
                    <span v-if=" parseInt(key) === isManage"><a-icon type="check" class="check-icon" style="float: right;line-height: 26px" /></span>
                  </div>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
        </span>
        <div slot="action" slot-scope="text, record" class="action-show">
          <span class="action-row" :class="selectId === record.userId?'active':''">
            <a @click="memberDetail(record)">详情</a>
            <a-divider type="vertical" />
            <a @click="changeStatus( record.isEnable=== 1? 0 : 1, [record.userId] )">{{ record.isEnable=== 1?'禁用':'启用' }}</a>
            <a-divider v-if="listType !== 'member'" type="vertical" />
            <a v-if="listType !== 'member'" class="ant-dropdown-link" @click="shiftOut('one', record)">移出</a>
            <a-divider v-if="listType === 'member'" type="vertical" />
            <a v-if="listType === 'member'" class="ant-dropdown-link" @click="resetPasswords(record)">重置密码</a>
            <a-divider v-if="listType === 'member'" type="vertical" />
            <a v-if="listType === 'member'" class="ant-dropdown-link" @click="deleteUser(record)">删除</a>
            <a-divider type="vertical" />
            <a class="ant-dropdown-link" @click="setEnterpriseManager(record)">{{ record.enterpriseManage === 1 ? '移除' : '设为' }}管理员</a>
            <!--            <a-divider v-if="listType === 'role'" type="vertical" />-->
            <!--            <a v-if="listType === 'role'" class="ant-dropdown-link" @click="setPermissions(record)">配置权限</a>-->
          </span>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import MemberModal from './memberModal'
import {
  userDepartmentsAdd,
  userRolesAdd
} from '@/api/user'
export default {
  name: 'RoleTable',
  components: {
    MemberModal
  },
  props: {
    listType: {
      type: String,
      default: 'member'
    },
    listData: {
      type: Object,
      default: () => {}
    },
    localeText: {
      type: Object,
      default: () => {}
    },
    parentData: {
      type: Object,
      default: () => {}
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          current: 1,
          defaultCurrent: 1,
          pageSize: 10,
          defaultPageSize: 10,
          pageSizeOptions: ['10', '20', '50', '100'],
          // hideOnSinglePage: true,
          showQuickJumper: true,
          showSizeChanger: true,
          total: 100,
          showTotal: (total) => `共 ${total} 条`
        }
      }
    }
  },
  data() {
    return {
      // columns
      columns: [
        {
          title: 'id',
          dataIndex: 'userId',
          key: 'userId',
          width: 100,
          scopedSlots: { customRender: 'userId' }
        },
        {
          title: '成员',
          dataIndex: 'nickName',
          key: 'nickName',
          width: 300,
          scopedSlots: { customRender: 'nickName' }
        },
        {
          title: '手机',
          dataIndex: 'tel',
          key: 'tel',
          width: 200,
          scopedSlots: { customRender: 'tel' }
        },
        {
          title: '是否管理员',
          dataIndex: 'enterpriseManage',
          key: 'enterpriseManage',
          width: 120,
          scopedSlots: { customRender: 'enterpriseManage' }
        },
        {
          title: '部门',
          key: 'departmentList',
          dataIndex: 'departmentList',
          width: 300,
          scopedSlots: { customRender: 'departmentList' }
        },
        {
          key: 'action',
          width: 320,
          // fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      columnsItem1: {
        title: '部门',
        key: 'departmentList',
        dataIndex: 'departmentList',
        // width: 300,
        ellipsis: true,
        scopedSlots: { customRender: 'departmentList' }
      },
      columnsItem2: {
        title: '职位',
        dataIndex: 'isManage',
        // width: 300,
        key: 'isManage',
        scopedSlots: { customRender: 'isManage' }
      },
      selectId: '',
      isChecked: false,
      // :custom-row="rowClick"-- customRow 属性：设置行属性

      rowClick: record => ({
        style: {
          // 字体颜色
          // 'color': record.userId === this.selectId ? '#e6f7ff' : 'rgba(0, 0, 0, 0.65)',
          // 行背景色
          'background-color': record.userId === this.selectId ? '#e6f7ff' : 'white'
          // // 字体类型
          // 'font-family': 'Microsoft YaHei',
          // // 下划线
          // 'text-decoration': 'underline',
          // // 字体样式-斜体
          // 'font-style': 'italic',
          // // 字体样式-斜体
          // 'font-weight': 'bolder'
        },
        // 事件
        on: {
          click: () => {
            // 点击改行时要做的事情
            // ......
            this.selectId = record.userId
          },
          // // 双击
          // dblclick: (event) => {},
          // // 右键菜单
          // contextmenu: (event) => {},
          // // 鼠标移入行
          // mouseenter: (event) => {},
          // // 鼠标移出行
          // mouseleave: (event) => {}
        }
      }),
      memberVisible: true,
      selectedRowKeys: [],
      positionData: { '0': '成员', '1': '主管' }
    }
  },
  watch: {
    listType: {
      handler(val) {
        if (val === 'member' || val === 'role') {
          this.columns.splice(4, 1, this.columnsItem1)
        } else {
          this.columns.splice(4, 1, this.columnsItem2)
        }
      },
      immediate: true,
      deep: true
    },
    listData() {
      this.selectedRowKeys = []
      this.isChecked = false
    }
  },
  created() {
  },
  methods: {
    // 行属性
    rowClassName() {
      return 'role-line'
    },
    onRowChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.isChecked = selectedRows.length > 0
    },
    onRowSelect(record, selected, selectedRows) {
      console.log('onSelect', record, selected, selectedRows)
    },
    onRowSelectAll(selected, selectedRows, changeRows) {
      console.log('onSelectAll', selected, selectedRows, changeRows)
    },
    // 改变职位
    clickMenu(record, key, isManage) {
      if (parseInt(key) === isManage) {
        return
      }
      this.$emit('changePosition', { userId: record.userId, key })
    },
    // 详情
    memberDetail(record) {
      this.$emit('memberDetail', record.userId)
    },
    // 启用/禁用
    changeStatus(isEnable, ids) {
      this.$emit('changeStatus', { isEnable, ids })
    },
    // 移出
    shiftOut(type, record) {
      switch (type) {
        case 'one':
          this.$emit('shiftOut', [record.userId])
          break
        case 'batch':
          this.$emit('shiftOut', this.selectedRowKeys)
          break
        case 'all':
          // eslint-disable-next-line no-case-declarations
          let list = this.listData.list.map((value) => {
            return value.userId
          })
          this.$emit('shiftOut', list)
          break
      }
    },
    // 重置密码
    resetPasswords(record) {
      const that = this
      this.$confirm({
        content: '是否重置此用户密码？',
        onOk() {
          that.$emit('resetPasswords', record.userId)
        },
        cancelText: '取消',
        onCancel() {
          that.$destroyAll()
        }
      })
    },
    // 删除
    deleteUser(record) {
      this.$emit('deleteUser', record)
    },
    // 配置权限
    setPermissions(data) {
      this.$emit('setPermissions', data.userId)
    },
    // 添加子部门
    addChildren(data) {
      this.$emit('addChildren', data)
    },
    // 编辑部门
    editDepartment(data) {
      this.$emit('editDepartment', data.departmentId)
    },
    // 删除部门
    deleteDepartment(id) {
      this.$emit('deleteDepartment', id)
    },
    // 编辑角色
    editRole() {
      this.$emit('editRole', this.parentData)
    },
    // 删除角色
    deleteRole() {
      this.$emit('deleteRole', this.parentData.roleId)
    },
    // 添加成员
    addMember(userList) {
      const userIds = userList.map((item) => {
        return item.userId
      })

      const data = {
        id: '',
        userIds
      }
      switch (this.listType) {
        case 'department':
          data.id = this.parentData.departmentId
          data.enterpriseId = this.parentData.enterpriseId
          userDepartmentsAdd(data).then((res) => {
            if (res['code'] !== 0) {
              this.$antMessage.error(res['message'])
              return
            }
            this.$antMessage.success(res['message'])
            this.$refs.memberModal.popoverVisible = false
            // this.showVisible = false
            this.$emit('userAdd')
          })
          break
        case 'role':
          data.id = this.parentData.roleId
          data.enterpriseId = this.parentData.enterpriseId
          userRolesAdd(data).then((res) => {
            if (res['code'] !== 0) {
              this.$antMessage.error(res['message'])
              return
            }
            this.$antMessage.success(res['message'])
            this.$refs.memberModal.popoverVisible = false
            // this.showVisible = false
            this.$emit('userAdd')
          })
          break
      }
    },
    // 分页、排序、筛选变化
    onChange(pagination) {
      this.$emit('onChange', pagination)
    },
    // 设为管理员
    setEnterpriseManager(record) {
      this.$emit('setEnterpriseManager', record)
    }
  }
}
</script>

<style lang="scss" scoped>
.enable{
  color: #0BC56A;
}
.disable{
  color: #EE3838;
}
.check-icon{
  color: #1890ff;
}
::v-deep .role-line{
  .action-row{
    display: none;
    &.active{
      display: block
    }
  }
  &:hover{
    .action-row{
      display: block;
    }
  }

}
.list-container-header{
  display: flex;
  justify-content: space-between;
  .title{
    font-size: 18px;
    font-weight: 500;
  }
}
</style>
