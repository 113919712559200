<template>
  <a-popover v-model="popoverVisible" title="" trigger="click" :placement="placement">
    <template slot="content">
      <a-tabs default-active-key="1" class="popover">
        <a-tab-pane key="1" tab="成员">
          <div class="popover-content p-10">
            <a-input-search v-model="keyword" placeholder="搜索成员" style="width: 100%" @search="onSearch" />
            <div v-show="!isSearch">
              <h3 class="mt-10">全部成员</h3>
              <div class="allMember" @scroll="scroll($event)">
                <div>
                  <a-directory-tree
                    v-model="memberCheckedKeys"
                    checkable
                    :check-strictly="true"
                    :expand-action="false"
                    :selectable="false"
                    :expandedKeys="expandedKeys"
                    :replace-fields="replaceFields"
                    :tree-data="bookUserList"
                    @expand="onExpand"
                    @check="onMemberCheck"
                  />
                </div>
              </div>
              <a-divider style="margin: 10px 0;" />
              <div class="content-tree" v-if="popoverVisible">
                <h3>部门成员</h3>
                <div class="deptMember">
                  <a-directory-tree
                    v-model="deptCheckedKeys"
                    checkable
                    :check-strictly="true"
                    :tree-data="treeData"
                    :expand-action="false"
                    :selectable="false"
                    :replace-fields="replaceFields"
                    :load-data="onLoadData"
                    @check="onDeptCheck"
                  />
                </div>

              </div>
            </div>
            <div v-show="isSearch" class="user-list pl-10 pr-10">
              <div>
                <a-directory-tree
                  v-model="searchCheckedKeys"
                  checkable
                  :check-strictly="true"
                  :expand-action="false"
                  :selectable="false"
                  :replace-fields="replaceFields"
                  :tree-data="searchList"
                  @check="onSearchCheck"
                />
              </div>
            </div>
            <div class="content-footer">
              <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="(e) => onCheckAllChange(e, 'member')">
                全选<span v-if="userList && userList.length > 0">（ {{ userList.length }} ）</span>
              </a-checkbox>
              <a-button v-if="modalType !== 'supervisor'" type="primary" class="mr-10" @click="addMember">确定</a-button>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane v-if="selectType === 'all'" key="department" tab="部门" force-render>
          <div class="popover-content p-10">
            <a-directory-tree
              v-model="departmentCheckedKeys"
              checkable
              :check-strictly="true"
              :expand-action="false"
              :replace-fields="replaceFields"
              :tree-data="departmentTree"
              @check="onDepartmentCheck"
            />
            <div class="content-footer">
              <a-checkbox :indeterminate="deptIndeterminate" :checked="deptCheckAll" @change="(e) => onCheckAllChange(e, 'department')">
                全选<span v-if="departmentList && departmentList.length > 0">（ {{ departmentList.length }} ）</span>
              </a-checkbox>
              <a-button v-if="modalType !== 'supervisor'" type="primary" class="mr-10" @click="addMember">确定</a-button>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane v-if="selectType === 'all'" key="role" tab="角色">
          <div class="popover-content p-10">
            <a-directory-tree
              v-model="roleCheckedKeys"
              checkable
              :check-strictly="true"
              :expand-action="false"
              :replace-fields="roleReplaceFields"
              :tree-data="roleTree"
              @check="onRoleCheck"
            />
            <div class="content-footer">
              <a-checkbox :indeterminate="roleIndeterminate" :checked="roleCheckAll" @change="(e) => onCheckAllChange(e, 'role')">
                全选<span v-if="roleList && roleList.length > 0">（ {{ roleList.length }} ）</span>
              </a-checkbox>
              <a-button v-if="modalType !== 'supervisor'" type="primary" class="mr-10" @click="addMember">确定</a-button>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </template>
    <a-button v-if="modalType === 'supervisor'" shape="circle" icon="plus" class="mr-10" @click="getData" />
    <a-button v-else type="primary" class="mr-10" @click="getData">添加成员</a-button>
  </a-popover>
</template>

<script>
import {
  bookUserList,
  commonDeptList,
  departmentList,
  roleList
} from '@/api/user'
export default {
  name: 'MemberModal',
  props: {
    visible: {
      type: Boolean
    },
    selectType: {
      type: String,
      default: 'personnel'
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    modalType: {
      type: String,
      default: ''
    },
    parentData: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      popoverVisible: false,
      deptCheckedKeys: {
        checked: []
      },
      memberCheckedKeys: {
        checked: []
      },
      searchCheckedKeys: {
        checked: []
      },
      departmentCheckedKeys: {
        checked: []
      },
      roleCheckedKeys: {
        checked: []
      },
      treeData: [],
      departmentTree: [],
      roleTree: [],
      searchList: [],
      indeterminate: false,
      deptIndeterminate: false,
      roleIndeterminate: false,
      checkAll: false,
      deptCheckAll: false,
      roleCheckAll: false,
      userList: [],
      departmentList: [],
      roleList: [],
      replaceFields: {
        children: 'list',
        title: 'departmentName',
        key: 'departmentId'
      },
      roleReplaceFields: {
        children: 'list',
        title: 'roleName',
        key: 'roleId'
      },
      keyword: '',
      allUserList: [],
      allDeptList: [],
      allRoleList: [],
      differenceList: [],
      bookUserList: [
        {
          departmentName: '全部成员',
          departmentId: '',
          isLeaf: false,
          checkable: false,
          list: []
        }
      ],
      busy: false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '50', '100'],
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total) => `共 ${total} 条`
      },
      timeID: '',
      isInitial: true,
      isSearch: false,
      expandedKeys: []
    }
  },
  watch: {
    // 监听 已选中的用户列表，用于实时传输数据
    userList(val) {
      this.checkedKeysChange(val)
      if (this.modalType === 'supervisor' && this.popoverVisible) {
        this.$emit('setManager', this.userList)
      }
    },
    // 监听 已选中的部门列表，用于实时传输数据
    departmentList(val) {
      this.deptCheckedKeysChange(val)
      if (this.modalType === 'supervisor' && this.popoverVisible) {
        this.$emit('setDepartment', this.departmentList)
      }
    },
    // 监听 已选中的部门列表，用于实时传输数据
    roleList(val) {
      this.roleCheckedKeysChange(val)
      if (this.modalType === 'supervisor' && this.popoverVisible) {
        this.$emit('setRole', this.roleList)
      }
    },
    popoverVisible(val) {
      if (!val) {
        this.expandedKeys = []
        this.bookUserList = [
          {
            departmentName: '全部成员',
            departmentId: '',
            isLeaf: false,
            checkable: false,
            list: []
          }
        ]
        this.treeData = []
        this.departmentTree = []
        this.roleTree = []
        this.deptCheckedKeys = this.setCheckKeyValue(this.deptCheckedKeys, [])
        this.memberCheckedKeys = this.setCheckKeyValue(this.memberCheckedKeys, [])
        this.departmentCheckedKeys = this.setCheckKeyValue(this.departmentCheckedKeys, [])
        this.roleCheckedKeys = this.setCheckKeyValue(this.roleCheckedKeys, [])
        this.userList = []
        this.departmentList = []
        this.roleList = []
        this.allUserList = []
        this.allDeptList = []
        this.allRoleList = []
        this.isInitial = true
        this.pagination.total = 0
        this.pagination.current = 1
        this.pagination.pageSize = 10
        this.keyword = ''
        this.searchList = []
        this.searchCheckedKeys = this.setCheckKeyValue(this.searchCheckedKeys, [])
      }
    },
    keyword(value) {
      const ms = 500
      clearTimeout(this.timeID)
      this.timeID = setTimeout(() => {
        this.keywordChange(value)
      }, ms)
    }
  },
  // created() {
  //   this.getCommonDeptList()
  //   this.getDepartmentList()
  //   this.getRoleList()
  // },
  methods: {
    setCheckKeyValue(checkedKeyObj, checked) {
      return Object.assign({}, checkedKeyObj, { checked: checked })
    },
    // 搜索 -- 切换
    keywordChange(value) {
      this.deleteDifference()
      if (value === '') {
        this.isSearch = false
      } else {
        this.isSearch = true
        this.onSearch(value)
      }
    },
    deleteDifference() {
      this.searchCheckedKeys = this.setCheckKeyValue(this.searchCheckedKeys, [])
      this.differenceList.forEach((item) => {
        this.allUserList = this.deleteListItem(this.allUserList, 'departmentId', item)
        this.userList = this.deleteListItem(this.userList, 'departmentId', item)
        this.memberCheckedKeys = this.setCheckKeyValue(this.memberCheckedKeys, this.deleteKeys(this.memberCheckedKeys.checked, item))
        this.deptCheckedKeys = this.setCheckKeyValue(this.deptCheckedKeys, this.deleteKeys(this.deptCheckedKeys.checked, item))
      })
      this.differenceList = []
    },
    // 获取全部成员接口
    getBookUserList(departmentId, pagination, type) {
      const data = {
        pageNum: pagination.current,
        pageSize: pagination.pageSize,
        param: {
          isEnable: '',
          departmentId: departmentId,
          keyword: this.keyword,
          enterpriseId: this.parentData.enterpriseId
        },
        orders: [
          { field: 'user_id', desc: false }
        ]
      }
      bookUserList(data).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        if (type === 'search') {
          this.searchCheckedKeys = this.setCheckKeyValue(this.searchCheckedKeys, [])
          this.searchList = res.data.list
          this.searchList.forEach((item) => {
            item.departmentName = item.nickName
            item.departmentId = item.userId
            item.isLeaf = true
            if (this.checkAll) {
              this.searchCheckedKeys.checked.push(item.userId)
            }
            let bol = true
            this.allUserList.forEach((value) => {
              if (value.userId === item.userId) {
                bol = false
              }
            })
            if (bol) {
              this.allUserList.push(item)
              this.differenceList.push(item.userId)
            }
            this.userList.forEach((element) => {
              if (element.departmentId === item.userId) {
                this.searchCheckedKeys.checked.push(item.departmentId)
              }
            })
          })
        } else {
          this.bookUserList[0].list = this.bookUserList[0].list.concat(res.data.list)
          this.bookUserList[0].list.forEach((item) => {
            item.departmentName = item.nickName
            item.departmentId = item.userId
            item.isLeaf = true
            let bol = true
            this.allUserList.forEach((value) => {
              if (value.userId === item.userId) {
                bol = false
              }
            })
            if (bol) {
              this.allUserList.push(item)
            }
            if (this.checkAll) {
              this.memberCheckedKeys.checked.push(item.userId)
            }
          })
          this.getMemberCheckedKeys()
          if (res.data['current'] < res.data.pages) {
            this.pagination.current++
          } else {
            this.busy = true
          }
        }
      })
    },
    // 获取部门 + 成员
    getCommonDeptList() {
      commonDeptList({ keyword: this.keyword, enterpriseId: this.parentData.enterpriseId || '' }).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        if (res.data && res.data.list) {
          const treeData = this.setTreeData(res.data.list, true)
          this.treeData = treeData
        }
      })
    },
    setTreeData(list, bol) {
      list.forEach((item) => {
        if (bol) {
          item.checkable = item.isUser
        } else {
          item.checkable = !item.isUser
          let bols = true
          this.allDeptList.forEach((value) => {
            if (value.departmentId === item.departmentId) {
              bols = false
            }
          })
          if (bols) {
            this.allDeptList.push(item)
          }
        }
        if (!item.isLeaf) {
          if (item.list) {
            this.setTreeData(item.list, bol)
          } else {
            item.isLeaf = true
          }
        }
      })
      return list
    },
    // 获取部门树
    getDepartmentList() {
      departmentList({ keyword: '', enterpriseId: this.parentData.enterpriseId }).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        if (res.data && res.data.list) {
          const departmentTree = this.setTreeData(res.data.list, true)
          this.departmentTree = departmentTree
        }
      })
    },
    // 获取角色
    getRoleList() {
      roleList({ keyword: '', enterpriseId: this.parentData.enterpriseId }).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.roleTree = res.data.map((item) => {
          item.isLeaf = true
          item.checkable = true
          this.allRoleList.push(item)
          return item
        })
        this.allRoleList = [...this.roleTree]
      })
    },
    // 成员选中状态改变
    checkedKeysChange() {
      if (this.userList.length === 0) {
        this.indeterminate = false
        this.checkAll = false
      } else if (this.userList.length === this.allUserList.length) {
        this.indeterminate = false
        this.checkAll = true
      } else {
        this.indeterminate = true
      }
    },
    // 搜索成员
    onSearch() {
      const pagination = {
        total: 0,
        current: 1,
        pageSize: 100
      }
      this.getBookUserList('', pagination, 'search')
    },
    // 部门选中状态改变
    deptCheckedKeysChange() {
      if (this.departmentList.length === 0) {
        this.deptIndeterminate = false
        this.deptCheckAll = false
      } else if (this.departmentList.length === this.allDeptList.length) {
        this.deptIndeterminate = false
        this.deptCheckAll = true
      } else {
        this.deptIndeterminate = true
      }
    },
    // 角色选中状态改变
    roleCheckedKeysChange() {
      if (this.roleList.length === 0) {
        this.roleIndeterminate = false
        this.roleCheckAll = false
      } else if (this.roleList.length === this.allRoleList.length) {
        this.roleIndeterminate = false
        this.roleCheckAll = true
      } else {
        this.roleIndeterminate = true
      }
    },
    // 选择--部门成员复选框
    onDeptCheck(checkedKeys, { checked, node }) {
      if (checked) {
        this.userList.push(node.dataRef)
        this.getMemberCheckedKeys()
      } else {
        this.userList = this.deleteListItem(this.userList, 'departmentId', node.dataRef.departmentId)
        this.memberCheckedKeys = this.setCheckKeyValue(this.memberCheckedKeys, this.deleteKeys(this.memberCheckedKeys.checked, node.dataRef.departmentId))
      }
    },
    getMemberCheckedKeys() {
      this.userList.forEach((value) => {
        this.bookUserList[0].list.forEach((item) => {
          if (value.departmentId === item.departmentId) {
            if (this.memberCheckedKeys.checked.indexOf(item.departmentId) === -1) {
              this.memberCheckedKeys.checked.push(item.departmentId)
            }
          }
        })
      })
    },
    // 选择--全部成员复选框
    onMemberCheck(checkedKeys, { checked, node }) {
      if (checked) {
        this.userList.push(node.dataRef)
        this.getDeptCheckedKeys()
      } else {
        this.userList = this.deleteListItem(this.userList, 'departmentId', node.dataRef.departmentId)
        this.deptCheckedKeys = this.setCheckKeyValue(this.deptCheckedKeys, this.deleteKeys(this.deptCheckedKeys.checked, node.dataRef.departmentId))
      }
    },
    getDeptCheckedKeys() {
      this.userList.forEach((value) => {
        this.setKeys(this.treeData, value.departmentId)
      })
    },
    // 选择--部门复选框
    onDepartmentCheck(checkedKeys, { checked, node }) {
      if (checked) {
        this.departmentList.push(node.dataRef)
      } else {
        this.departmentList = this.deleteListItem(this.departmentList, 'departmentId', node.dataRef.departmentId)
      }
    },
    // 选择--角色复选框
    onRoleCheck(checkedKeys, { checked, node }) {
      if (checked) {
        this.roleList.push(node.dataRef)
      } else {
        this.roleList = this.deleteListItem(this.roleList, 'roleId', node.dataRef.roleId)
      }
    },
    setKeys(list, value) {
      list.forEach((item) => {
        if (item.isUser) {
          if (item.departmentId === value) {
            if (this.deptCheckedKeys.checked.indexOf(item.departmentId) === -1) {
              this.deptCheckedKeys.checked.push(item.departmentId)
            }
          }
        } else {
          if (!item.isLeaf) {
            this.setKeys(item.list, value)
          }
        }
      })
    },
    // 选择--搜索成员复选框
    onSearchCheck(checkedKeys, { checked, node }) {
      if (checked) {
        this.userList.push(node.dataRef)
        this.getMemberCheckedKeys()
      } else {
        this.userList = this.deleteListItem(this.userList, node.dataRef.departmentId)
        this.deptCheckedKeys = this.setCheckKeyValue(this.deptCheckedKeys, this.deleteKeys(this.deptCheckedKeys.checked, node.dataRef.departmentId))
        this.memberCheckedKeys = this.setCheckKeyValue(this.memberCheckedKeys, this.deleteKeys(this.memberCheckedKeys.checked, node.dataRef.departmentId))
      }
    },
    // 删除列表中指定 key
    deleteKeys(list, id) {
      const index = list.indexOf(id)
      if (index !== -1) {
        list.splice(index, 1)
      }
      return list
    },
    // 删除列表中指定用户
    deleteListItem(list, key, id) {
      list.forEach((item, index) => {
        if (item[key] === id) {
          list.splice(index, 1)
        }
      })
      return list
    },
    // 全选状态
    onCheckAllChange(e, type) {
      switch (type) {
        case 'member':
          this.checkAll = e.target.checked
          if (e.target.checked) {
            this.indeterminate = false
            this.userList = [...this.allUserList]
            this.getMemberCheckedKeys()
            this.setList(this.treeData, e.target.checked)
            if (this.isSearch) {
              this.searchList.forEach((item) => {
                if (this.searchCheckedKeys.checked.indexOf(item.departmentId) === -1) {
                  this.searchCheckedKeys.checked.push(item.departmentId)
                }
              })
            }
          } else {
            this.deptCheckedKeys = this.setCheckKeyValue(this.deptCheckedKeys, [])
            this.memberCheckedKeys = this.setCheckKeyValue(this.memberCheckedKeys, [])
            this.userList = []
            this.searchCheckedKeys = this.setCheckKeyValue(this.searchCheckedKeys, [])
            this.$forceUpdate()
          }
          break
        case 'department':
          this.deptCheckAll = e.target.checked
          if (e.target.checked) {
            this.deptIndeterminate = false
            this.departmentList = [...this.allDeptList]
            this.departmentCheckedKeys = this.setCheckKeyValue(this.departmentCheckedKeys, this.allDeptList.map((item) => { return item.departmentId }))
          } else {
            this.departmentCheckedKeys = this.setCheckKeyValue(this.departmentCheckedKeys, [])
            this.departmentList = []
            this.$forceUpdate()
          }
          break
        case 'role':
          if (e.target.checked) {
            this.roleCheckAll = e.target.checked
            this.roleIndeterminate = false
            this.roleList = [...this.allRoleList]
            this.roleCheckedKeys = this.setCheckKeyValue(this.roleCheckedKeys, this.allRoleList.map((item) => { return item.roleId }))
          } else {
            this.roleCheckedKeys = this.setCheckKeyValue(this.roleCheckedKeys, [])
            this.roleList = []
            this.$forceUpdate()
          }
          break
        default:
      }
    },
    setList(treeData) {
      treeData.forEach((element) => {
        if (element.checkable) {
          if (this.deptCheckedKeys.checked.indexOf(element.departmentId) === -1) {
            this.deptCheckedKeys.checked.push(element.departmentId)
          }
        }
        if (element.list && element.list.length > 0) {
          this.setList(element.list)
        }
      })
    },
    // 确定
    addMember() {
      if (this.userList.length < 1) {
        this.$antMessage.warning('请选择成员')
        return
      }
      this.$emit('addMember', this.userList)
    },
    // 部门成员懒加载
    onLoadData(treeNode) {
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          resolve()
          return
        }
        const data = {
          current: 1,
          pageSize: 10,
          param: {
            isEnable: '',
            departmentId: treeNode.dataRef.departmentId,
            keyword: '',
            enterpriseId: this.parentData.enterpriseId
          },
          orders: [
            { field: 'user_id', desc: false }
          ]
        }
        bookUserList(data).then((res) => {
          if (res['code'] !== 0) {
            this.$antMessage.error(res['message'])
            return
          }
          const list = res.data.list
          list.forEach((item) => {
            item.departmentName = item.nickName
            item.departmentId = item.userId
            item.isLeaf = true
            item.isUser = true
            item.checkable = true
            treeNode.dataRef.list.push(item)
            if (this.checkAll) {
              this.memberCheckedKeys.checked.push(item.userId)
            }
            let bol = true
            this.allUserList.forEach((value) => {
              if (value.userId === item.userId) {
                bol = false
              }
            })
            if (bol) {
              this.allUserList.push(item)
            }
          })
          this.getDeptCheckedKeys()
          this.treeData = [...this.treeData]
          resolve()
        })
      })
    },
    // 监听滚动
    scroll(e) {
      const that = this
      that.ms = 500
      clearTimeout(this.timeID)
      this.timeID = setTimeout(() => {
        const target = e.target
        if ((target.scrollHeight - target.scrollTop) > (target.clientHeight - 10)) {
          if (!this.busy) { this.getBookUserList('', this.pagination) }
        }
      }, that.ms)
    },
    // 获取初始数据
    getData() {
      this.getCommonDeptList()
      this.getDepartmentList()
      this.getRoleList()
    },
    // 初次展开全部成员
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      if (this.isInitial) {
        this.getBookUserList('', this.pagination)
        this.isInitial = false
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.popover{
  width: 368px;
  .popover-content {
    width: 368px;
    margin-bottom: 40px;
    .content-tree {
      .deptMember{
        overflow-y: auto;
        max-height: 22vh;
      }

    }
    .content-footer, .list-footer{
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      bottom: 0;
      width: calc(100% - 20px) ;
      height: 50px;
    }

  }
  .user-list{
    height: 40vh;
    overflow-y: auto;
    .user-list-item{
      height: 30px;
      line-height: 30px;
      display: flex;
      justify-content: space-between;
    }
  }
}
::v-deep .ant-tree.ant-tree-directory > li span.ant-tree-checkbox,::v-deep .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-checkbox{
  position: absolute;
  right: 10px;
}
.allMember{
  //min-height: 8vh;
  max-height: 18vh;
  overflow-y: auto;
}
.switcher-icon{
  font-size: 10px;
}
.pl-24{
  padding-left: 24px;
}
.pr-5{
  padding-right: 5px;
}
</style>
