<template>
  <div>
    <a-modal
      :title="editType?'编辑部门':'添加部门'"
      :visible="addDepartmentVisible"
      :confirm-loading="confirmLoading"
      ok-text="确定"
      cancel-text="取消"
      width="23%"
      @ok="handleOk('departmentForm')"
      @cancel="handleCancel('departmentForm')"
    >
      <a-form-model
        ref="departmentForm"
        :model="departmentData"
        :rules="rules"
        :layout="'horizontal'"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item ref="departmentName" label="部门名称" prop="departmentName">
          <a-input
            v-model="departmentData.departmentName"
            placeholder="请输入部门名称"
            @blur="() => {$refs.departmentName.onFieldBlur()}"
          />
        </a-form-model-item>
        <a-form-model-item ref="userIds" label="部门主管" prop="userIds">
          <div class="manager-list">
            <MemberModal
              class="mb-10"
              :modal-type="'supervisor'"
              :visible="memberVisible"
              :parent-data="parentData"
              :placement="'bottomRight'"
              @setManager="setManager"
              @setAllManager="setAllManager"
            />
            <span v-for="(item, index) in managerList" :key="index" class="mr-10 mb-10">
              <a-badge :offset="[-2,3]" :number-style="{display:'none'}">
                <a-icon
                  slot="count"
                  type="close-circle"
                  theme="filled"
                  class="manager-close"
                  @click="deleteManager(index)"
                />
                <a-tooltip>
                  <template slot="title">
                    {{ item.nickName }}
                  </template>
                  <a-avatar :size="32" shape="circle" icon="user" :src="item.portraitFileUrl ? item.portraitFileUrl : undefined" />
                </a-tooltip>
              </a-badge>

            </span>
          </div>
        </a-form-model-item>
        <!--<a-form-model-item ref="name" label="图标" prop="icon">-->
        <!--  <div class="manager-list">-->
        <!--    <a-row :gutter="30">-->
        <!--      <template v-for="(item, index) in managerIconList">-->
        <!--        <a-col :key="index" class="gutter-row" :span="4">-->
        <!--          <a-icon-->
        <!--            class="icon-list-item mb-10"-->
        <!--            :class="item.id == selectId?'active':''"-->
        <!--            :type="item.type"-->
        <!--            @click="selectIcon(item)"-->
        <!--          />-->
        <!--        </a-col>-->
        <!--      </template>-->
        <!--    </a-row>-->
        <!--  </div>-->
        <!--</a-form-model-item>-->
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import MemberModal from './memberModal'
import { departmentAdd, departmentUpdate } from '@/api/user'
const managerIconList = [
  { type: 'edit', id: '1101' },
  { type: 'form', id: '1102' },
  { type: 'copy', id: '1103' },
  { type: 'scissor', id: '1104' },
  { type: 'delete', id: '1105' },
  { type: 'snippets', id: '1106' },
  { type: 'diff', id: '1107' },
  { type: 'highlight', id: '1108' },
  { type: 'area-chart', id: '1109' },
  { type: 'pie-chart', id: '1110' },
  { type: 'account-book', id: '1111' },
  { type: 'appstore', id: '1112' }
]
export default {
  name: 'AddDepartment',
  components: {
    MemberModal
  },
  props: {
    addDepartmentVisible: {
      type: Boolean,
      default: false
    },
    editType: {
      type: Boolean,
      default: false
    },
    parentData: {
      type: Object,
      default: () => {}
    },
    departmentData: {
      type: Object,
      default: () => {
        return {
          parentDepartmentId: '',
          departmentName: '',
          departmentId: '',
          userList: []
        }
      }
    }
  },
  data() {
    return {
      confirmLoading: false,
      // form: {
      //   parentDepartmentId: '',
      //   departmentName: '',
      //   userIds: ['1']
      // },
      rules: {
        departmentName: [{ required: true, message: '请输入部门名称', trigger: 'blur' }]
      },
      modalTitle: '添加新部门',
      managerList: this.editType ? this.departmentData.userList : [],
      managerIconList: [],
      selectId: '',
      memberVisible: false
    }
  },
  mounted() {
    this.managerIconList = managerIconList
  },
  methods: {
    // 弹框确定
    handleOk(ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          this.confirmLoading = true
          let userIds = []
          if (this.managerList.length > 0) {
            userIds = this.managerList.map((value) => {
              return value.userId
            })
          }
          if (this.editType) {
            const data = {
              departmentName: this.departmentData.departmentName,
              departmentId: this.departmentData.departmentId,
              parentDepartmentId: this.departmentData.parentDepartmentId,
              enterpriseId: this.parentData.enterpriseId,
              userIds: userIds
            }
            departmentUpdate(data).then((res) => {
              if (res['code'] !== 0) {
                this.$antMessage.error(res['message'])
                this.confirmLoading = false
                return
              }
              this.$antMessage.success(res['message'])
              this.confirmLoading = false
              this.$emit('operationSuccess',true)
              this.$emit('closeModal', false)
            })
          } else {
            const data = {
              departmentName: this.departmentData.departmentName,
              parentDepartmentId: this.departmentData.parentDepartmentId,
              enterpriseId: this.parentData.enterpriseId,
              userIds: userIds
            }
            departmentAdd(data).then((res) => {
              if (res['code'] !== 0) {
                this.$antMessage.error(res['message'])
                this.confirmLoading = false
                return
              }
              this.$antMessage.success(res['message'])
              this.confirmLoading = false
              this.$emit('operationSuccess', false)
              this.$emit('closeModal', false)
            })
          }
        } else {
          return false
        }
      })
    },
    // 弹框关闭
    handleCancel(ruleForm) {
      this.$refs[ruleForm].resetFields()
      this.$emit('closeModal', false)
      // this.visible = false
    },
    //  选择图标
    selectIcon(item) {
      this.selectId = item.id
    },
    // 设置部门主管
    setManager(userList) {
      this.managerList = userList
    },
    // 全选设置部门主管
    setAllManager(list) {
      this.managerList = list
    },
    // 删除选择人员
    deleteManager(index) {
      this.managerList.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.manager-img {
  border-radius: 16px;
}

.manager-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.icon-list-item {
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  background-color: rgba(42, 51, 64, .2);
  font-size: 18px;
  color: #ffffff;

  &:hover {
    background-color: #40a9ff;
  }

  &.active {
    background-color: #40a9ff;
  }
}
.ant-badge {
  ::v-deep .anticon.manager-close {
    display: none;
  }

  &:hover {
    ::v-deep .anticon.manager-close {
      color: #f5222d;
      display: block;
    }
  }
}
</style>
