<template>
  <div>
    <a-modal
      :title="modalTitle"
      :visible="addRoleVisible"
      :confirm-loading="confirmLoading"
      ok-text="确定"
      cancel-text="取消"
      width="20%"
      @ok="handleOk('departmentForm')"
      @cancel="handleCancel('departmentForm')"
    >
      <a-form-model
        ref="departmentForm"
        :model="roleData"
        :rules="rules"
        :layout="'vertical'"
      >
        <a-form-model-item ref="roleName" label="角色名称" prop="roleName">
          <a-input
            v-model="roleData.roleName"
            placeholder="请输入角色名称"
            @blur="
              () => {
                $refs.roleName.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <!--<a-form-model-item ref="name" label="图标" prop="icon">-->
        <!--  <div class="manager-list">-->
        <!--    <a-row :gutter="30">-->
        <!--      <template v-for="(item, index) in managerIconList">-->
        <!--        <a-col :key="index" class="gutter-row" :span="4">-->
        <!--          <a-icon class="icon-list-item mb-10" :class="item.id == selectId?'active':''" :type="item.type" @click="selectIcon(item)" />-->
        <!--        </a-col>-->
        <!--      </template>-->
        <!--    </a-row>-->
        <!--  </div>-->
        <!--</a-form-model-item>-->
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  roleAdd,
  roleUpdate
} from '@/api/user'
const managerIconList = [
  { type: 'edit', id: '1101' },
  { type: 'form', id: '1102' },
  { type: 'copy', id: '1103' },
  { type: 'scissor', id: '1104' },
  { type: 'delete', id: '1105' },
  { type: 'snippets', id: '1106' },
  { type: 'diff', id: '1107' },
  { type: 'highlight', id: '1108' },
  { type: 'area-chart', id: '1109' },
  { type: 'pie-chart', id: '1110' },
  { type: 'account-book', id: '1111' },
  { type: 'appstore', id: '1112' }
]
export default {
  name: 'AddRole',
  props: {
    addRoleVisible: {
      type: Boolean,
      default: false
    },
    detailType: {
      type: String,
      default: ''
    },
    roleData: {
      type: Object,
      default: () => {
        return {
          roleName: ''
        }
      }
    },
    parentData: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入角色名称'))
      } else {
        const name = value.trim()
        if (name === '') {
          callback(new Error('角色名称不能全为空格'))
        }
        callback()
      }
    }
    return {
      confirmLoading: false,
      rules: {
        roleName: [{ validator: validatePass, trigger: 'blur' }]
      },
      modalTitle: '添加新角色',
      managerIconList: [],
      selectId: ''
    }
  },
  mounted() {
    this.managerIconList = managerIconList
  },
  methods: {
    // 弹框确定
    handleOk(ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          this.confirmLoading = true
          switch (this.detailType) {
            case 'add':
              roleAdd({ roleName: this.roleData.roleName, enterpriseId: this.parentData.enterpriseId }).then((res) => {
                this.confirmLoading = false
                if (res['code'] !== 0) {
                  this.$antMessage.error(res['message'])
                  this.$emit('closeRoleModal', false)
                  return
                }
                this.$antMessage.success(res['message'])
                this.$emit('addRoleSuccess', false )
                this.$emit('closeRoleModal', false)
              })
              break
            case 'edit':
              roleUpdate({
                roleId: this.roleData.roleId,
                roleName: this.roleData.roleName
              }).then((res) => {
                this.confirmLoading = false
                if (res['code'] !== 0) {
                  this.$antMessage.error(res['message'])
                  this.$emit('closeRoleModal', false)
                  return
                }
                this.$antMessage.success(res['message'])
                this.$emit('addRoleSuccess', true)
                this.$emit('closeRoleModal', false)
              })
              break
          }
        } else {
          return false
        }
      })
    },
    // 弹框关闭
    handleCancel(ruleForm) {
      this.$refs[ruleForm].resetFields()
      this.$emit('closeRoleModal', false)
    },
    //  选择图标
    selectIcon(item) {
      this.selectId = item.id
    }
  }
}
</script>

<style lang="scss" scoped>
.manager-img{
  border-radius: 16px;
}
.manager-list{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.icon-list-item{
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  background-color: rgba(42,51,64,.2);
  font-size: 18px;
  color: #ffffff;
  &:hover{
    background-color: #40a9ff;
  }
  &.active{
    background-color: #40a9ff;
  }
}
</style>
